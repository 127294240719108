import React, { useState, useEffect } from 'react';
import { AccountsService } from 'services/accounts_service';
import { Box, Spinner, Grid, GridItem, FormLabel } from '@chakra-ui/react';
import CustomDataTable from 'components/custom_data_table/custom_data_table';
import Loader from 'components/loader';
import FormSelect from "components/select/FormSelect";

export function AmzAccounts({ accountId }) {
  const service = new AccountsService();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState();
  const [apiProgram, setAPIProgram] = useState('campaign'); //Enum: "billing" "campaign" "paymentMethod" "store" "report" "account" "posts"
  const [accessLevel, setAccessLevel] = useState('edit'); //Enum: "edit" "view"

  const fetchData = async () => {
    setLoading(true);
    try {
      const {accounts} = await service.getAmzAccounts(accountId, apiProgram, accessLevel)
      setProfiles(accounts);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(async () => {
    fetchData()
  }, [apiProgram, accessLevel])

  
  const columns = [
      {
          name: "Profile ID",
          cell: ({ profileId }) => profileId, 
          width:"200px", 
          sortable: true,
          selector: (row) => row.profileId,
          sortField: "profileId",
      },
      {
          name: "Profile Name",
          cell: ({ accountInfo }) => accountInfo.name,
          width:"200px", 
          sortable: true,
          selector: (row) => row.accountInfo.name,
          sortField: "accountInfo.name",
      },
      {
          name: "Marketplace",
          cell: ({ accountInfo }) => accountInfo.marketplaceStringId,
          width:"200px", 
          sortable: true,
          selector: (row) => row.accountInfo.marketplaceStringId,
          sortField: "accountInfo.marketplaceStringId",
      },
      {
          name: "Type",
          cell: ({ accountInfo }) => accountInfo.type,
          width:"100px", 
          sortable: true,
          selector: (row) => row.accountInfo.type,
          sortField: "accountInfo.type",
      },
      {
          name: "Country",
          cell: ({ countryCode }) => countryCode,
          width:"100px", 
          sortable: true,
          selector: (row) => row.countryCode,
          sortField: "countryCode",
      },
      {
          name: "Currency",
          cell: ({ currencyCode }) => currencyCode,
          width:"100px", 
          sortable: true,
          selector: (row) => row.currencyCode,
          sortField: "currencyCode",
      },
      {
          name: "Timezone",
          cell: ({ timezone }) => timezone,
          width:"200px", 
          sortable: true,
          selector: (row) => row.timezone,
          sortField: "timezone",
      }
  ]

  const FILTER_KEY = "AMZ_ACCOUNTS"

  const apiProgramOptions = [
      {value: 'campaign', label: 'Campaign'},
      {value: 'billing', label: 'Billing'},
      {value: 'paymentMethod', label: 'Payment Method'},
      {value: 'store', label: 'Store'},
      {value: 'report', label: 'Report'},
      {value: 'account', label: 'Account'},
      {value: 'posts', label: 'Posts'},
  ]

  const accessLevelOptions = [
      {value: 'edit', label: 'Edit'},
      {value: 'view', label: 'View'},
  ]
  return (
    <div>
      <Grid templateColumns='repeat(5, 1fr)' gap={3}>
          <GridItem>
              <FormLabel margin={2}>API Program</FormLabel>
              <FormSelect onChange={(e)=>{setAPIProgram(e.value)}} options={apiProgramOptions} defaultValue={apiProgramOptions[0]} />
          </GridItem>
          <GridItem>
              <FormLabel margin={2}>Access Level</FormLabel>
              <FormSelect onChange={(e)=>{setAccessLevel(e.value)}} options={accessLevelOptions} defaultValue={accessLevelOptions[0]} />
          </GridItem>
      </Grid>
      <br/><br/>
      <CustomDataTable
            progressPending={loading}
            progressComponent={<Loader />}
            filterKey = {FILTER_KEY}
            remotePagination = {false}
            columns= {columns}
            data= {profiles}
            keyField="profileId"
        />
    </div>
  )
}
