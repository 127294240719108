import React from 'react'
import { Grid, GridItem, Button } from '@chakra-ui/react'
import NumInput from 'components/input/NumInput';
import TextInput from 'components/input/TextInput';

/**
 * Variable number of line items with description and price
 * @param {*} param0 
 * @returns 
 */
export default function LineItems({ lineItems, setLineItems }) {

  const handleLineItemChange = (idx, lineItem) => {
    const newLineItems = [...lineItems];
    newLineItems[idx] = lineItem;
    setLineItems(newLineItems);
  }

  const addLineItem = () => {
    setLineItems([...lineItems, { description: '', amount: 0 }]);
  }

  return (
      <>
        {
          lineItems.map((data, index) => {
            return (
              <Grid templateColumns='repeat(5, 1fr)' gap={3} mt={3}>
                <GridItem colStart={1} colEnd={3}>
                  <TextInput placeholder='Description' value={data.description} onChange={(e) => handleLineItemChange(index, {...data, description: e})} />
                </GridItem>
                <GridItem colStart={3} colEnd={5}>
                  <NumInput placeholder='Amount' value={data.amount} onChange={(e) => handleLineItemChange(index, {...data, amount: e})} />
                </GridItem>
                <a colorScheme='red' mt={2} onClick={() => setLineItems(lineItems.filter((_, i) => i !== index))}>Remove</a>
              </Grid>
            )})
        }
        <div>
          <a colorScheme='teal' mt={2} onClick={addLineItem}>Add Line Item</a>
        </div>
      </>

  )
}
