import { Box, Button, Grid, GridItem } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { catchAsync } from 'util';
import FormSelect from "components/select/FormSelect";
import { OrganizationsService } from "services/organizations_service";
import { AccountsService } from 'services/accounts_service';
import Loader from 'components/loader';




export default function ChangeOrg({ onComplete, accountId }) {
    const orgService = new OrganizationsService();
    const service = new AccountsService();
    const [org, setOrg] = useState('');
    const[organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState();
    const [showPermissionBox, setShowPermissionBox] = useState(false)

    const fetchPaginatedData =  async () => {
        try{
          const {records} = await orgService.getAllOrganizations(['name', '_id'])
          setOrganizations(records.map((record) => ({ "label":  record.name + ' - ' + record._id, "value": record })))
        }catch (e) {
          console.error(e)
        }
      };
    
      const fetchData = async () => {
        fetchPaginatedData()
      }
    
      useEffect(() => {
        fetchData()
      }, []);


    const handleSubmit = catchAsync(async() => {
        setLoading(true)
        onComplete()
        await service.changeOrg(accountId, org._id)
        setLoading(false)
    }, "Changed Organization successfully", "Error changing Organization");

    return <Box>
            { loading ? <Loader /> : !showPermissionBox && <div>
                <div>Select Organization</div>
                <br />
                <FormSelect onChange={(e)=>{setOrg(e.value)}} options={organizations} isSearchable={true} isClearable={true} />
                <Button mt={4} colorScheme='teal' onClick={()=>{setShowPermissionBox(true)}}>Submit</Button>
            </div>}
            {showPermissionBox && <div>
              <div>Are you sure you want to change the Organization</div>
              <br/>
              <Grid templateColumns='repeat(5, 1fr)' gap={3} mt={4}>
                <GridItem><Button mt={4} colorScheme='teal' onClick={handleSubmit}>Yes</Button></GridItem>
                <GridItem><Button mt={4} colorScheme='teal' onClick={onComplete}>Cancel</Button></GridItem>
              </Grid>
            </div>}
        </Box>
}