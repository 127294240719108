import React, { useEffect, useState } from "react";
import { Box, Button, Icon, Spinner, Grid, GridItem, FormLabel } from "@chakra-ui/react";
import { OrganizationsService } from "services/organizations_service";
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { MdCircle, MdPadding } from "react-icons/md";
import { AiFillCheckCircle, AiFillExclamationCircle } from 'react-icons/ai';
import moment from 'moment';
import { useTablePreferences } from "contexts/table_preferences_contex";
import { useFilter } from "contexts/filter_context";
import SearchBox from "components/input/SearchBox";
import Loader from "components/loader";
import FormSelect from "components/select/FormSelect";


export default function Organizations() {
  const service = new OrganizationsService();
  const[organizations,setOrganizations] = useState([]);
  const[loading,setLoading] = useState(true);
  const { page, getPreferences, sortDetails, filters } = useTablePreferences()
  const { setFilterKey, addFilter, removeFilterForAttribute, resetFilters } = useFilter();
  const [totalRows, setTotalRows] = useState(0)
  const FILTER_KEY = "organization"
  const { perPage } = getPreferences(FILTER_KEY)
  const [filterText, setFilterText] = useState("")


  useEffect(()=> {
    setFilterKey(FILTER_KEY)
    resetFilters(FILTER_KEY)
  },[])
  
  const fetchPaginatedData =  async (page, sortColumn, sortDirection, filterSpec, filterText) => {
    setLoading(true);
    try{
      const {records, count } = await service.getOrganizations({ page, perPage, sortColumn, sortDirection, filterSpec, filterText })
      setTotalRows(count);
      setOrganizations(records);
    }catch (e) {
      console.error(e)
    }
    setLoading(false);
  };

  const fetchData = async () => {
    fetchPaginatedData(page, sortDetails.column || "created" , sortDetails.direction || "desc", JSON.stringify(filters[FILTER_KEY]), filterText)
  }

  useEffect(() => {
    fetchData()
  }, [perPage, page, sortDetails, filters, filterText]);

  const getNameCell = ({_id,name}, idx) => {
    return (
        <div className={`organization-${idx}-name mb-1 p-2`}>
            <a className="inline-anchor" style = {{color:'blue',cursor:'pointer'}} href={`/admin/organizations/${_id}`}>
                {name} 
            </a>
        </div>
    )
 }

  const columns = [
    {
      name: "Active",
      cell: ({ status }) => (
        <Icon
          as={MdCircle}
          width="8px"
          height="8px"
          color={status === "created" ? "green.400" : "red"}
        />
      ), 
      width:"100px", 
      sortable: true,
      selector: (row) => row.status,
      sortField: "status",
    },
    {
      name: "Name", 
      cell: getNameCell,  
      width:"300px",
      sortable: true, 
      selector: (row )=> row.name,
      sortField: "name",
    },
    {
      name: "Billing",
      cell: ({billing}) => (
        <Icon
          as = {billing ? AiFillCheckCircle : AiFillExclamationCircle }
          width="15px"
          height="15px"
          color={billing ? "green.400" : "red"}
          margin={2}
        />
      ),
      width:"100px", 
      sortable: true,
      selector: (row)=> row.billing,
      sortField: "billing",
    },
    {
      name: "Plan",
      cell:({plan})=>(
        <p>{plan ?? '--'}</p>
      ),
      width:"100px", 
      sortable: true,
      selector: (row)=> row.plan,
      sortField: "plan",
    },
    {
      name: "Marketing Stream",
      cell:({is_marketing_stream_enabled})=>(
          <Icon
          as={MdCircle}
          width="8px"
          height="8px"
          color={is_marketing_stream_enabled ? "green.400" : "red"}
          />
      ),
      width:"140px", 
      sortable: true,
      selector: (row)=> row.is_marketing_stream_enabled,
      sortField: "is_marketing_stream_enabled",
    },
    {
      name: "Billing Day",
      cell:({billing})=>(
        <p>{billing?.day_of_month}</p>
      ),
      width:"100px", 
      sortable: true,
      selector: (row)=> row.billing,
      sortField: "billing.day_of_month",
    },
    {
      name: "Bill Generation Day",
      cell:({billing})=>(
        <p>{billing?.generation_day}</p>
      ),
      width:"100px", 
      sortable: true,
      selector: (row)=> row.billing,
      sortField: "billing.generation_day",
    },
    {
      name: "Valid Until",
      cell:({valid_until})=>(
        valid_until?
        <p>
          {moment(valid_until).format('DD-MM-yyyy hh:mm A')}
        </p>:"Not configured"
      ),
      width:"155px", 
      sortable: true,
      selector: (row)=> row.valid_until,
      sortField: "valid_until",
    },
    {
      name: "Billing Hourly Data",
      cell: ({billing_hourly_data}) => {
        return(
          <div>
            <div>Complementary SOV Count : {billing_hourly_data['complementary_sov_count']}</div>
            <div>Chargeable SOV Count : {billing_hourly_data['chargeable_sov_count']}</div>
            <div>Allowed SOV Count : {billing_hourly_data['allowed_sov_count']}</div>
          </div>
        )
      },
      width:"230px", 
      sortable: true,
      selector: (row)=> row.billing_hourly_data,
      sortField: "billing_hourly_data",
    },
    {
      name: "Billing Daily Data",
      cell: ({billing_daily_data}) => {
        return(
          <div>
            <div>Complementary SOV Count : {billing_daily_data['complementary_sov_count']}</div>
            <div>Chargeable SOV Count : {billing_daily_data['chargeable_sov_count']}</div>
            <div>Allowed SOV Count : {billing_daily_data['allowed_sov_count']}</div>
          </div>
        )
      },
      width:"230px", 
      sortable: true,
      selector: (row)=> row.billing_daily_data,
      sortField: "billing_daily_data",
    },
    {
      name: "Id",
      width: "250px",
      sortable: true,
      selector: (row) => row._id,
      sortField: "_id",
    },
    {
      name: "Created",
      cell: ({created}) => (
        <p>{created ? moment(created).format('DD-MM-yyyy hh:mm A') : '--'}</p>
      ), 
      width:"175px", 
      sortable: true,
      selector: (row )=> row.created,
      sortField: "created",
    },
    {
      name: "Shared Entities",
      width: "90px",
      selector: (row) => row.num_shared_entities,
    },
    {
      name: "Last Modified",
      cell: ({last_modifed}) => (
        <p>{last_modifed ? moment(last_modifed).format('DD-MM-yyyy hh:mm A') : '--'}</p>
      ), 
      width:"175px", 
      sortable: true,
      selector: (row )=> row.last_modifed,
      sortField: "last_modifed",
    }
  ];

  const addFilters = (attribute, val, operator="=", remove=true) =>{
    if (remove) {
      removeFilterForAttribute(attribute)
    }
    if (val != 'all' && val != 'no_value') {
      addFilter({attribute: attribute, label: attribute, operator: operator, value: val}, FILTER_KEY) 
    }
  }

  const getExpiryDate = (days) => {
    let date = new Date();
    date.setDate(date.getDate() + days)
    return date.toJSON()
  }

  const addValidityFilter=(value, gt_value, remove=false)=>{
    if (value)
      addFilters('valid_until', value, '<=')
    if (gt_value) {
      addFilters('valid_until', gt_value, '>=', remove)
    }
  }

  const status_filter_actions = [
      { label: 'All', value: 'all' },
      { label: 'Active', value: 'created' },
      { label: 'Inactive', value: 'inactive' }
  ]

  const plan_filter_actions = [
      { label: 'Any', value: 'all' },
      { label: 'Trial', value: 'trial' },
      { label: 'Standard', value: 'standard' }
  ]

  const billing_filter_actions = [
      { label: 'Any', value: 'all' },
      { label: 'Configured', value: 1 },
      { label: 'Not Configured', value: 0 }
  ]

  const validity_filter_actions = [
    { label: 'All', value: 'all' },
    { label: 'Expired', value: new Date().toJSON()},
    { label: 'Expiring in next 7 days', value: getExpiryDate(7), gt_value: new Date().toJSON() },
    { label: 'Expiring in next 14 days', value: getExpiryDate(14), gt_value: new Date().toJSON() },
    { label: 'Expiring in next 30 days', value: getExpiryDate(30), gt_value: new Date().toJSON() },
    { label: 'Expiring after 30 days', value: 'no_value', gt_value: getExpiryDate(30), remove: true }
]

  const marketing_stream_filter_actions = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ]
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!loading && <SearchBox placeholder='Search organization name' value={filterText} onEnter={(v) => setFilterText(v)}/>}
      <Grid templateColumns='repeat(5, 1fr)' gap={3}>
        <GridItem>
            <FormLabel margin={2}>Status</FormLabel>
            <FormSelect onChange={(e)=>{addFilters('status', e.value)}} options={status_filter_actions} defaultValue={status_filter_actions[0]} />
        </GridItem>
        <GridItem>
            <FormLabel margin={2}>Billing</FormLabel>
            <FormSelect onChange={(e)=>{addFilters('billing', e.value)}} options={billing_filter_actions} defaultValue={billing_filter_actions[0]} />
        </GridItem>
        <GridItem>
            <FormLabel margin={2}>Plan</FormLabel>
            <FormSelect onChange={(e)=>{addFilters('plan', e.value)}} options={plan_filter_actions} defaultValue={plan_filter_actions[0]} />
        </GridItem>
        <GridItem>
            <FormLabel margin={2}>Validity</FormLabel>
            <FormSelect onChange={(e)=>{addValidityFilter(e.value, e.gt_value, e.remove)}} options={validity_filter_actions} defaultValue={validity_filter_actions[0]} />
        </GridItem>
        <GridItem>
            <FormLabel margin={2}>Marketing Stream</FormLabel>
            <FormSelect onChange={(e)=>{addFilters('is_marketing_stream_enabled', e.value)}} options={marketing_stream_filter_actions} defaultValue={marketing_stream_filter_actions[0]} />
        </GridItem>
      </Grid>
      <br/><br/>
      <CustomDataTable
        progressPending = {loading}
        progressComponent={<Loader />}
        filterKey={FILTER_KEY}
        remotePagination
        columns={columns}
        data= {organizations}
        keyField="_id"
        paginationTotalRows={totalRows}
        />  
    </Box>
  );
};