import ConfigureBilling from 'features/billing/configure_billing';
import moment from 'moment';
import { DEFAULT_BILLING } from 'constants';
import { useEffect, useState } from 'react';
import InlineTextEdit from 'components/input/InlineTextEdit';
import { OrganizationsService } from 'services/organizations_service';
import { planOptions, statusOptions } from 'constants';
import { Box, GridItem, Grid, FormLabel, useColorModeValue } from '@chakra-ui/react';
import FormSelect from 'components/select/FormSelect';
import { Button } from '@chakra-ui/react';
import Swal from 'sweetalert2'
import { ScheduleBilling } from 'features/billing/schedule_billing';
import DatePicker from "react-datepicker";
import ConfigureCustomReport from 'features/configure_custom_report';
import NumInput from 'components/input/NumInput';
import { catchAsync } from 'util';
import DropdownButton from "components/buttons/dropdown_button";
import { Checkbox } from '@chakra-ui/react'

export default function OrganizationOverview({ organization, forceRefresh = () => { } }) {
    const sources = [{label:"Zoho", value:"ZOHO"}, {label:"Stripe", value:"STRIPE"}]
    const service = new OrganizationsService()
    const [organizationName, setOrganizationName] = useState(organization.name);
    const [plan, setPlan] = useState(organization.plan);
    const [status, setStatus] = useState(organization.status);
    const [validUntil, setValidUntil] = useState(organization.valid_until ? moment(organization.valid_until).format('YYYYMMDD') : null);
    const [showBilling, setShowBilling] = useState(organization.billing);
    const [showCustomReport, setShowCustomReport] = useState(organization.report_config);
    const [source, setSource] = useState((organization?.external || [])[0]?.source || sources[0].value)
    const [external_id, setExternal_id] = useState((organization?.external || [])[0]?.external_id)
    const boxBg = useColorModeValue("white", "blackAlpha.300");
    const [shareableLinksEnabled, setShareableLinksEnabled] = useState(organization.shareable_links_enabled);
    
    useState(() => {
        setShareableLinksEnabled(organization.shareable_links_enabled);
    }, [organization])

    async function onSubmit() {
        try {
            await service.updateOrganization({ org_id: organization._id, name: organizationName, plan: plan, status: status, valid_until: validUntil, external: {'external_id': external_id, 'source': source}, 'shareable_links_enabled': shareableLinksEnabled })
            forceRefresh();
            Swal.fire({
                title: 'Success',
                text: 'Organization updated successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3B82F6'
            })
        }
        catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error in updating organization',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3B82F6'
            })
        }
    }

    const handleValidUntilChange = (date) => { 
        setValidUntil(moment(date).format("YYYYMMDD"));
    }

    const enableMarketingStream = catchAsync(async () => {
        await service.updateOrganization({ org_id: organization._id, enable_marketing_stream: true})
    }, 'Marketing Stream enabled for the Acccount', 'Failed to enable marketing stream');

    const disableMarketingStream = catchAsync(async (subs) => {
        await service.updateOrganization({ org_id: organization._id, disable_marketing_stream: true, subs})
    }, 'Marketing Stream disabled for the Acccount', 'Failed to disable marketing stream');

    const initiateOnboardingHandler = catchAsync(async () => {
        await service.initiateOnboarding(organizationName)
    }, 'Onboarding initiated successfully', 'Failed to initiate Onboarding');

    const actions = [
        {label: 'Enable Marketing Stream', onClick: () => enableMarketingStream(true) },
        {label: 'Disable Marketing Stream (Paid)', onClick: () => disableMarketingStream('paid') },
        {label: 'Disable Marketing Stream (All)', onClick: () => disableMarketingStream('all') },
        {label: 'Initiate Onboarding', onClick: () => initiateOnboardingHandler() }
    ]

    const yes_no_options = [{label: 'Yes', value: true}, {label: 'No', value: false}]
    return (
        <div>
            <DropdownButton label="Actions" actions={actions} />
            <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5}>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} >
                    <GridItem>
                        Name:
                    </GridItem>
                    <GridItem colStart={2} ml={6}>
                        <InlineTextEdit value={organizationName} onChange={(e) => setOrganizationName(e)} />
                    </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        Valid Until:
                    </GridItem>
                    <GridItem colStart={2}>
                        <div style={{border: '1px solid #ddd', padding: '5px'}}>
                            <DatePicker onChange={handleValidUntilChange} selected={validUntil ? moment(validUntil, 'YYYYMMDD').toDate() : null} />
                        </div>
                    </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        <FormLabel>Plan</FormLabel>
                    </GridItem>
                    <GridItem colStart={2}>
                        <FormSelect options={planOptions} value={plan} onChange={(e) => setPlan(e.value)} />
                    </GridItem>
                </Grid>
                
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        <FormLabel>Status</FormLabel>
                    </GridItem>
                    <GridItem colStart={2}>
                        <FormSelect options={statusOptions} value={status} onChange={(e) => setStatus(e.value)} />
                    </GridItem>
                </Grid>
                <FormLabel>External Services</FormLabel>
                    <Grid templateColumns='repeat(5, 1fr)' gap={1} mt={1}>
                        <GridItem colStart={1} colEnd={2}>
                             <InlineTextEdit value={external_id} onChange={(e) => setExternal_id(e)} />
                        </GridItem>
                        <GridItem colStart={2} colEnd={3}>
                            <FormSelect options={sources} value={source} onChange={(e) => setSource(e.value)} placeholder="SOURCE" />
                        </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        Marketing Stream Enabled:
                    </GridItem>
                    <GridItem colStart={2}>
                        {JSON.stringify(organization.is_marketing_stream_enabled)}
                    </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        Created:
                    </GridItem>
                    <GridItem colStart={2}>
                        {organization.created ? moment(organization.created).format("DD-MM-yyyy hh:mm A") : '--'}
                    </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        Last Modified:
                    </GridItem>
                    <GridItem colStart={2}>
                        {organization.last_modifed ? moment(organization.last_modifed).format("DD-MM-yyyy hh:mm A") : '--'}
                    </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                    <GridItem>
                        <FormLabel>Shareable Links Enabled</FormLabel>
                    </GridItem>
                    <GridItem colStart={2} colEnd={3}>
                        <FormSelect options={yes_no_options} value={shareableLinksEnabled} onChange={(e) => setShareableLinksEnabled(e.value)} />
                    </GridItem>
                </Grid>
                <Button colorScheme='teal' mt={8} onClick={onSubmit} >Save</Button>
            </Box>

            <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
                <div>Actions</div>
                <ScheduleBilling orgId={organization._id} />
            </Box>

            <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
                <div>Configure custom report:</div>
                {!showCustomReport && <span>Custom reports not configured. <span onClick={() => setShowCustomReport(true)} style={{ color: 'blue', cursor: 'pointer' }}>(Configure now)</span></span>}
                {showCustomReport && <ConfigureCustomReport orgId={organization._id} reportConfig={organization.report_config ?? []} />}
            </Box>

            <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
                <div>Configure Billing:</div>
                {!showBilling && <span>Billing not configured. <span onClick={() => setShowBilling(true)} style={{ color: 'blue', cursor: 'pointer' }}>(Configure now)</span></span>}
                {showBilling && <ConfigureBilling orgId={organization._id} billing={organization.billing ?? DEFAULT_BILLING} />}
            </Box>
        </div>
    )
}