import React from 'react';
import Select from 'react-select';

export default function FormSelect({ value, onChange, options, minWidth, isSearchable=false, isClearable=false, defaultValue=null }) {
  return (
    <div style={{ minWidth: minWidth}}>
      <Select
        options={options}
        value={options.filter((obj) => obj.value == value)[0]}
        onChange={onChange}
        menuPlacement="auto"
        menuPosition="fixed"
        isSearchable={isSearchable}
        isClearable={isClearable}
        menuPortalTarget={document.body} 
        styles={{ menuPortal: base => ({ ...base, zIndex: 10000 }) }}
        defaultValue={defaultValue}
      />
    </div>
  );
}
