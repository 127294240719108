import React, { useEffect, useState } from "react";
import { Box, Button, Icon, Spinner, Grid, GridItem, FormLabel } from "@chakra-ui/react";
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { AccountsService } from "services/accounts_service";
import { useTablePreferences } from "contexts/table_preferences_contex";
import SearchBox from "components/input/SearchBox";
import { useFilter } from "contexts/filter_context";
import { MdCircle } from "react-icons/md";
import moment from 'moment';
import Loader from "components/loader";
import BasicModal from "components/modals/basic";
import Users from "features/users";
import { Checkbox } from '@chakra-ui/react'
import FormSelect from "components/select/FormSelect";




export default function Accounts({orgId,accountIds,inTab=false}){
    const service = new AccountsService();
    const [accounts, setAccounts] = useState([]);
    const [clickedAccount, setClickedAccount] = useState();
    const[loading,setLoading] = useState(true);
    const { page, getPreferences, sortDetails, filters } = useTablePreferences()
    const { setFilterKey, addFilter, removeFilterForAttribute, resetFilters } = useFilter();
    const [totalRows, setTotalRows] = useState(0)
    const FILTER_KEY = "account"
    const { perPage } = getPreferences(FILTER_KEY)
    const [filterText, setFilterText] = useState("")
    const [showDetails, setShowDetails] = useState(false)
    const [amcInstanceExists, setAMCInstanceExists] = useState('all')
    const [dspAdvertiserExists, setDSPAdvertiserExists] = useState('all')

    useEffect(() => {
        // This is not the ideal fix for this issue, we should add support for multiple tables on same page.
        if (!clickedAccount) {
            setFilterKey(FILTER_KEY)
            resetFilters(FILTER_KEY)
        }
    }, [clickedAccount])

    const fetchPaginatedData = async(page, sortColumn, sortDirection, filterSpec, filterText, showDetails) => {
        setLoading(true);
        try {
            const {records, count } = await service.getAccounts({orgId, page, perPage, sortColumn, sortDirection, filterSpec, filterText, showDetails, amcInstanceExists: amcInstanceExists === 'all' ? undefined : amcInstanceExists, dspAdvertiserExists: dspAdvertiserExists === 'all' ? undefined : dspAdvertiserExists, accountIds})
            setTotalRows(count);
            setAccounts(records)    
        }
        catch (e) {
            console.error(e)
          }
          setLoading(false);
    };

    const fetchData = async () => {
        fetchPaginatedData(page, sortDetails.column ||"created" , sortDetails.direction || "desc", JSON.stringify(filters[FILTER_KEY]), filterText, showDetails, amcInstanceExists, dspAdvertiserExists)
    }

    useEffect(() => {
        fetchData()
    }, [perPage, page, sortDetails, filters, filterText, showDetails, amcInstanceExists, dspAdvertiserExists]);

    const getNameCell = ({_id, account_name}, idx) => {
        return (
            <div className={`account-${idx}-name mb-1 p-2`}>
                <a className="inline-anchor" style = {{color:'blue',cursor:'pointer'}} href={`/admin/accounts/${_id}`}>
                    {account_name} 
                </a>
            </div>
        )    
    }
    const columns = [
        {
            name: "Active",
            cell: ({ active }) => (
                <Icon
                as={MdCircle}
                width="8px"
                height="8px"
                color={active  ? "green.400" : "red"}
                margin={2}
                />
            ), 
            width:"90px", 
            sortable: true,
            selector: (row) => row.active,
            sortField: "active",   
        },
        {
            name: "Id",
            width: "220px",
            sortable: true,
            selector: (row) => row._id,
            sortField: "_id",
        },
        {
            name: "Account Name",
            cell: getNameCell,  
            width:"220px",
            sortable: true, 
            selector: (row )=> row.account_name,
            sortField: "account_name",
        },
        {
            name: "Organizations",
            cell: ({orgs}) => <div>
                {orgs.map(({ name: orgName, _id }, idx) =>
                    <div className={`account-${idx}-name mb-1 p-2`}>
                        <a className="inline-anchor" style={{ color: 'blue', cursor: 'pointer' }} href={`/admin/organizations/${_id}`}>
                            {orgName}
                        </a>
                    </div>)}
            </div>,
            width: "220px",
            sortable: true,
            selector: (row) => row.account_name,
            sortField: "account_name",
        },
        {
            name: "Users",
            cell: (row) => <a onClick={() => setClickedAccount(row)} style={{ color: 'blue', cursor: 'pointer' }}>View</a>,
            width: '80px',
        },
        {
            name:"Country", 
            cell:({country_code})=>{
                return(
                    <div>{country_code}</div>
                )
            },
            width:"100px", 
            sortable: true,
            selector: (row )=> row.country_code,
            sortField: "country_code",
        },
        {
            name:"Currency",
            cell:({currency_code})=>{
                return(
                    <div>{currency_code}</div>
                )
            },
            width:"100px",
            sortable:true,
            selector:(row)=> row.currency_code,
            sortField: "currency_code"
        },
        {
            name:"Fetch Data",
            cell:({fetch_data})=>{
                return(
                    <Icon
                        as={MdCircle}
                        width="8px"
                        height="8px"
                        color={fetch_data ? "green.400" : "red"}
                        margin={5}    
                    />
                )
            },
            width:"125px",
            sortable:true,
            selector:(row)=> row.fetch_data,
            sortField: "fetch_data"
        },
        {
            name:"SOV - Hourly", 
            cell:({sov_keywords_hourly})=>{
                return(
                    <div>{sov_keywords_hourly}</div>
                )
            },
            width:"150px", 
            sortable: true,
            selector: (row )=> row.sov_keywords_hourly,
            sortField: "sov_keywords_hourly",
            omit: !showDetails
        },
        {
            name:"SOV - Daily", 
            cell:({sov_keywords_daily})=>{
                return(
                    <div>{sov_keywords_daily}</div>
                )
            },
            width:"150px", 
            sortable: true,
            selector: (row )=> row.sov_keywords_daily,
            sortField: "sov_keywords_daily",
            omit: !showDetails
        },
        {
            name:"Campaign Launches", 
            cell:({campaign_launches_count})=>{
                return(
                    <div>{campaign_launches_count}</div>
                )
            },
            width:"175px", 
            sortable: true,
            selector: (row )=> row.campaign_launches_count,
            sortField: "campaign_launches_count",
            omit: !showDetails
        },
        {
            name:"Campaign Management", 
            cell:({campaign_management_rulesets})=>{
                return(
                    <div>
                        <div>Active Rulesets : {campaign_management_rulesets['active_rulsets']}</div>
                        <div>Associated Campaigns : {campaign_management_rulesets['active_associations']}</div>
                    </div>
                )
            },
            width:"200px", 
            sortable: true,
            selector: (row )=> row.campaign_management_rulesets,
            sortField: "campaign_management_rulesets",
            omit: !showDetails
        },
        {
            name:"Bid Management", 
            cell:({bid_management_rulesets})=>{
                return(
                    <div>
                        <div>Active Rulesets : {bid_management_rulesets['active_rulsets']}</div>
                        <div>Associated Campaigns : {bid_management_rulesets['active_associations']}</div>
                    </div>
                )
            },
            width:"200px", 
            sortable: true,
            selector: (row )=> row.bid_management_rulesets,
            sortField: "bid_management_rulesets",
            omit: !showDetails
        },
        {
            name:"Target Management", 
            cell:({target_management_rulesets})=>{
                return(
                    <div>
                        <div>Active Rulesets : {target_management_rulesets['active_rulsets']}</div>
                        <div>Associated Campaigns : {target_management_rulesets['active_associations']}</div>
                    </div>
                )
            },
            width:"200px", 
            sortable: true,
            selector: (row )=> row.target_management_rulesets,
            sortField: "target_management_rulesets",
            omit: !showDetails
        },
        {
            name:"Dayparting", 
            cell:({dayparting_count})=>{
                return(
                    <div>
                        <div>Active Rulesets : {dayparting_count['strategies']}</div>
                        <div>Associated Campaigns : {dayparting_count['associated_campaigns']}</div>
                    </div>
                )
            },
            width:"150px", 
            sortable: true,
            selector: (row )=> row.dayparting_count,
            sortField: "dayparting_count",
            omit: !showDetails
        },
        {
            name:"Product Labels", 
            cell:({product_labels_count})=>{
                return(
                    <div>{product_labels_count}</div>
                )
            },
            width:"150px", 
            sortable: true,
            selector: (row )=> row.product_labels_count,
            sortField: "product_labels_count",
            omit: !showDetails
        },
        {
            name:"Campaign Labels", 
            cell:({campaign_labels_count})=>{
                return(
                    <div>{campaign_labels_count}</div>
                )
            },
            width:"150px", 
            sortable: true,
            selector: (row )=> row.campaign_labels_count,
            sortField: "campaign_labels_count",
            omit: !showDetails
        },
        {
            name:"SP Accounts", 
            cell:({sp_accounts_count})=>{
                return(
                    <div>{sp_accounts_count}</div>
                )
            },
            width:"150px", 
            sortable: true,
            selector: (row )=> row.sp_accounts_count,
            sortField: "sp_accounts_count",
            omit: !showDetails
        },
        {
            name:"Smart Recommendations Applied", 
            cell:({smart_recommendations_count})=>{
                return(
                    <div>{smart_recommendations_count}</div>
                )
            },
            width:"175px", 
            sortable: true,
            selector: (row )=> row.smart_recommendations_count,
            sortField: "smart_recommendations_count",
            omit: !showDetails
        },
        {
            name:"Automated Campaigns", 
            cell:({automated_campaigns_count})=>{
                return(
                    <div>{automated_campaigns_count}</div>
                )
            },
            width:"175px", 
            sortable: true,
            selector: (row )=> row.automated_campaigns_count,
            sortField: "automated_campaigns_count",
            omit: !showDetails
        },
        {
            name:"Enabled Campaigns", 
            cell:({enabled_campaigns_count})=>{
                return(
                    <div>{enabled_campaigns_count}</div>
                )
            },
            width:"175px", 
            sortable: true,
            selector: (row )=> row.enabled_campaigns_count,
            sortField: "enabled_campaigns_count",
            omit: !showDetails
        },
        {
            name:"Last Report Email",
            cell:({email_schedule})=>{
                if (!email_schedule)
                    return <div>Not Set</div>
                return(
                    <p>{moment(email_schedule[0].last_sent_date,"YYYYMMDD").format('DD/MM/YYYY')}</p>
                )
            },
            width:"155px", 
            sortable: true,
            selector: (row )=> row.email_schedule,
            sortField: "email_schedule",
        },
        {
            name:"Last Updated",
            cell:({last_updated})=>{
                return(
                    <p>{last_updated ? moment(last_updated).format('DD-MM-yyyy hh:mm A') : '--'}</p>
                )
            },
            width:"175px",
            sortable: true,
            selector: (row )=> row.last_updated,
            sortField:"last_updated"
        },
        {
            name: "Created",
            cell: ({ created }) => {
                return (
                    <p>{created ? moment(created).format('DD-MM-yyyy hh:mm A') : '--'}</p>
                )
            },
            width: "175px",
            sortable: true,
            selector: (row) => row.created,
            sortField: "created"
        },
    ];

    const addFilters = (attribute, val) =>{
        removeFilterForAttribute(attribute)
        if (val != 'all') {
            addFilter({attribute: attribute, label: attribute, operator: "=", value: val}, FILTER_KEY) 
        }
    }

    const status_filter_actions = [
        { label: 'All', value: 'all' },
        { label: 'Active', value: true },
        { label: 'Inactive', value: false }
    ]
    
    const fetchdata_filter_actions = [
        { label: 'All', value: 'all' },
        { label: 'Enabled', value: true },
        { label: 'Disabled', value: false }
    ]

    const amc_instance_options = [
        {label: 'All', value: 'all'},
        {label: 'Yes', value: true},
        {label: 'No', value: false}
    ]

    const getTables=()=>{
        return(<div>
            {!loading && <SearchBox placeholder='Search account name' value={filterText} onEnter={(v) => setFilterText(v)}/>}
            <Grid templateColumns='repeat(5, 1fr)' gap={3}>
                <GridItem>
                    <FormLabel margin={2}>Status</FormLabel>
                    <FormSelect onChange={(e)=>{addFilters('active', e.value)}} options={status_filter_actions} defaultValue={status_filter_actions[0]} />
                </GridItem>
                <GridItem>
                    <FormLabel margin={2}>Fetch Data</FormLabel>
                    <FormSelect onChange={(e)=>{addFilters('fetch_data', e.value)}} options={fetchdata_filter_actions} defaultValue={fetchdata_filter_actions[0]} />
                </GridItem>
                <GridItem>
                    <FormLabel margin={2}>AMC Instance</FormLabel>
                    <FormSelect onChange={(e)=>{setAMCInstanceExists(e.value)}} options={amc_instance_options} defaultValue={amc_instance_options[0]} />
                </GridItem>
                <GridItem>
                    <FormLabel margin={2}>DSP Advertiser</FormLabel>
                    <FormSelect onChange={(e)=>{setDSPAdvertiserExists(e.value)}} options={amc_instance_options} defaultValue={amc_instance_options[0]} />
                </GridItem>
            </Grid>
            <Checkbox onChange={(e)=>{setShowDetails(e.target.checked)}} defaultValue={showDetails} style={{marginRight: '30px', marginTop: '10px'}}>Show Detailed Usage</Checkbox>
            <br/><br/>
            <CustomDataTable
                progressPending={loading}
                progressComponent={<Loader />}
                filterKey = {FILTER_KEY}
                remotePagination = {true}
                columns= {columns}
                data= {accounts}
                keyField="_id"
                paginationTotalRows={totalRows}
            />
            <BasicModal size="5xl" title="Users" isOpen={clickedAccount} onClose={() => setClickedAccount(undefined)}>
                {clickedAccount && <Users accountId={clickedAccount._id} accountName={clickedAccount.account_name} inTab />}
            </BasicModal>
        </div>);
    }
    return(
        inTab ? getTables() :
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {getTables()}
        </Box>
    );
};