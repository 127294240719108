import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import Debugging from 'features/debugging';
import { useParams } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Spinner } from '@chakra-ui/react';
import { AccountsService } from 'services/accounts_service';
import { usePageState } from "contexts/page_state_context";
import { AccountUsage } from './account_usage';
import { AmzAccounts } from './amz_accounts';
import AccountOverview from './overview';
import { accountPageTabs } from 'constants';
import { DeactivateAccount } from './deactivate_account';
import Users from "features/users";

export default function Account() {
    const service = new AccountsService();
    let { accountId, tabKey } = useParams();
    const [loading, setLoading] = useState(true);
    const { setPageTitle } = usePageState();
    const [account, setAccount] = useState();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => { 
        if (tabKey) {
            const tab = accountPageTabs.find(t => t.key === tabKey);
            if (tab) {
                setActiveTab(tab.index);
            }
        }
    }, [])

    const fetchData = async () => {
        setLoading(true);
        const {account} = await service.getAccountDetails(accountId);
        setAccount(account)
        setLoading(false);
    }
    useEffect(async () => {
        fetchData()
    }, [])

    useEffect(() => {
        if (!account) {
            setPageTitle("")
        } else {
            setPageTitle(account.account_name)
        }
    }, [account])

    const handleTabChange = (index) => { 
        const tabKey = accountPageTabs.find(tab => tab.index === index).key;
        window.history.replaceState(null, "", `/admin/accounts/${accountId}/${tabKey}`)
        setActiveTab(index);
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {loading && <Spinner color='blue.500' />}
            {!loading && <Tabs size="md" variant="enclosed" isLazy index={activeTab} onChange={handleTabChange}>
                <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Debugging</Tab>
                    <Tab>Account Usage</Tab>
                    <Tab>Deactivate Account</Tab>
                    <Tab>Users</Tab>
                    <Tab>AMZ Accounts</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <AccountOverview account={account} accountId={accountId} forceRefresh={fetchData} />
                    </TabPanel>
                    <TabPanel>
                        <Debugging accountId={accountId} inTab />
                    </TabPanel>
                    <TabPanel>
                        <AccountUsage accountId={accountId} />
                    </TabPanel>
                    <TabPanel>
                        <DeactivateAccount accountId={accountId} account={account} />
                    </TabPanel>
                    <TabPanel>
                        <Users accountId={accountId} accountName={account.account_name} inTab/>
                    </TabPanel>
                    <TabPanel>
                        <AmzAccounts accountId={accountId} />
                    </TabPanel>
                </TabPanels>
            </Tabs>}
        </Box>
    )
}
