import {
  Box,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Switch
} from "@chakra-ui/react";
import { MdDownload } from 'react-icons/md'
import React, { useState, useEffect } from "react";
import { UsersService } from 'services/users_service';
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { MdCircle } from "react-icons/md";
import { useTablePreferences } from "contexts/table_preferences_contex";
import { useFilter } from "contexts/filter_context";
import SearchBox from "components/input/SearchBox";
import Loader from "components/loader";
import LinkText from "components/link_text";
import { downloadCSV } from "util";
import { catchAsync } from "util";
import BasicModal from "components/modals/basic";
import Accounts from "features/accounts";

export default function Users({ orgId, accountId, accountName, inTab=false }) {
  const service = new UsersService();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState();
  const { page, getPreferences, sortDetails,  filters } = useTablePreferences();
  const { setFilterKey } = useFilter();
  const [totalRows, setTotalRows] = useState(0)
  const FILTER_KEY = "Users"
  const { perPage } = getPreferences(FILTER_KEY)
  const [filterText, setFilterText] = useState("")
  const [clickedUser, setClickedUser] = useState(false)
  useEffect(async () => {
    setFilterKey(FILTER_KEY)
  }, [])

  const fetchPaginatedData = async (page, sortColumn, sortDirection, filterSpec, filterText) => {
    setLoading(true);
    try {
      const { users, count } = await service.getUsers({ page, perPage, sortColumn, sortDirection, filterSpec, filterText, orgId, accountId })
      setTotalRows(count);
      setUsers(users);
    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  };

  const fetchData = async () => {
    fetchPaginatedData(page, sortDetails.column || "name", sortDetails.direction || "desc", JSON.stringify(filters[FILTER_KEY]), filterText)
  }

  useEffect(() => {
    fetchData();
  }, [perPage, page, sortDetails, filters, filterText]);

  const getMasqueradeCell = ({user_id}) => {
    let href = `${service.BASE_APP_URL}/?masquerade_id=${user_id}`
    if (accountId && accountName) href += `&account_id=${accountId}&account_name=${accountName}`
    return <LinkText href={href} target="_blank">{user_id}</LinkText>
  }

  const getOrganizationsCell = ({orgs}) => {
    return <>
      {
        orgs.map((org) => {
          return <>
              <LinkText href={`/admin/organizations/${org.id}`} target="_blank">{org.role}</LinkText> | <br />
          </>
        })
      }
    </>
  }

  const exportAbstract = () => {
    const dataToExport = users.map((user) => {
      let prodObj = {
        name: user.name,
        email: user.email,
        phone: user.phone,
        newsletter: user.newsletter,
        user_id: user.user_id,
        status: user.status,
      }
      return prodObj;
    })
    downloadCSV(dataToExport, 'users.csv')
  }

  const toggleUserActive = catchAsync(async (id) => {
    await service.toggleUserActive(id);
    await fetchData();
  }, 'User updated successfully', 'Error updating user');
 
  const columns = [
    {
      name: 'Status', cell: ({ status }) => (
        <Icon as={MdCircle} width="15px" height="10px" color={status === "active" ? "green.400" : "red"} />
      ), width: "90px",
      sortable: true,
      sortField: 'status'
    },
    {
      name: "Id",
      width: "220px",
      sortable: true,
      selector: (row) => row._id,
      sortField: "_id",
    },
    {
      name: 'Name',
      width: "250px",
      selector: row => row.name,
      sortable: true,
      sortField: 'name'
    },
    {
      name: 'Email',
      width: "200px",
      cell: row => <a style={{color: 'blue'}} href={`mailto:${row.email}`}>{row.email}</a>,
      sortable: true,
      sortField: 'email'
    },
    {
      name: 'Phone',
      cell: row => <a style={{ color: 'blue' }} href={`tel:${row.phone}`}>{row.phone}</a>,
      sortable: true,
      sortField: 'phone'
    },
    {
      name: 'User ID',
      cell: getMasqueradeCell,
      sortable: true,
      sortField: 'user_id'
    },
    {
      name: 'Organizations',
      cell: getOrganizationsCell,
      sortable: true,
      sortField: 'organizations'
    },
    {
      name: 'Accounts',
      cell: row  => <a onClick={() => setClickedUser(row)} style={{ color: 'blue', cursor: 'pointer' }}>View</a>,
    },
    {
      name: 'Active',
      cell: ({ status, _id }) => <div>
        <Switch isChecked={status == 'active'} onChange={(e) => toggleUserActive(_id)} />
      </div>,
      sortable: true,
      sortField: 'status'
    }
  ];

  function getTable() {
    return <div>
      {!loading && <Grid templateColumns='repeat(5, 1fr)'>
        <GridItem>
          <SearchBox placeholder='Search User' value={filterText} onEnter={(v) => setFilterText(v)} />
        </GridItem>
        <GridItem colStart={6}>
          <IconButton ml={2} icon={<MdDownload />} borderRadius='20px' onClick={exportAbstract} colorScheme='blue' aria-label='Export users' />
        </GridItem>
      </Grid>}
      <CustomDataTable
        progressPending={loading}
        progressComponent={<Loader />}
        columns={columns}
        data={users}
        filterKey={FILTER_KEY}
        remotePagination
        paginationRowsPerPageOptions={[10, 50, 90, 300, 1000]}
        keyField="_id"
        paginationTotalRows={totalRows}
      />
      <BasicModal size="5xl" title="Users" isOpen={clickedUser} onClose={() => setClickedUser(undefined)}>
            {clickedUser && <Accounts accountIds={clickedUser.acc_ids} inTab />}
        </BasicModal>
    </div>
  }

  return (
    inTab ? getTable() : <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {getTable()}
    </Box>

  );
}



