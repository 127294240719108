import API from './api';

export class UsersService extends API {
    constructor() {
        super('admin/users');
    }
    
    async getUsers({ page, perPage, sortColumn, sortDirection, filterSpec, filterText, orgId, accountId }) {
        let params=this.getPaginationParams(page, perPage, sortColumn, sortDirection, filterSpec, filterText,orgId);
        if (orgId) params.org_id = orgId
        if (accountId) params.account_id = accountId
        return await this.get({
            params:params
        });
    }

    async toggleUserActive(id) {
        return await this.put({
            body: {id}
        })
    }
}