export const DEFAULT_CONDITION = {
  attribute: 'clicks',
  operator: 'gte',
  value: 0,
}

export const DEFAULT_FEE = {
  fixed_fee: 0,
  percentage: 0,
  metric: 'spend',

}

export const DEFAULT_SLAB = {
  name: 'Slab',
  lower_bound: 0,
  upper_bound: 0,
  metric: 'spend',
  minimum_fee: 0,
  fee: DEFAULT_FEE,
  conditions: [DEFAULT_CONDITION],
}

export const DEFAULT_SOV = {
  complementary: 0,
  fee_per_kw_month: 0,
  fixed_fee: 0,
  allowed_sov_count: 0,
};

export const DEFAULT_BILLING = {
  day_of_month: 3,
  account_slabs: [DEFAULT_SLAB],
  overall_slabs: [DEFAULT_SLAB],
  currency: "USD",
  sov_config: DEFAULT_SOV,
  sov_config_hourly: DEFAULT_SOV,
};

export const conditionOptions = [
  {value: 'clicks', label: 'Clicks'},
  {value: 'orders', label: 'Orders'},
  {value: 'impressions', label: 'Impressions'},
  {value: 'cpc', label: 'CPC'},
  {value: 'acos', label: 'ACoS (%)'},
  {value: 'sales', label: 'Sales'},
  {value: 'spend', label: 'Spend'},
  {value: 'conversions', label: 'Conversions'},
  {value: 'conversion_rate', label: 'Conversion Rate (%)'},
]

export const operatorOptions = [
  {label: '<=', value: 'lte'},
  {label: '<', value: 'lt'},
  {label: '=', value: 'eq'},
  {label: '>', value: 'gt'},
  {label: '>=', value: 'gte'},
];

export const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'INR', label: 'INR' },
  { value: 'EUR', label: 'EUR' }
]

export const DEFAULT_REPORT_CONFIG = {
  report_type: "",
  report_path: "",
  report_handler: "",
}

export const planOptions = [
  {value: 'trial', label: 'Trial'},
  {value: 'standard', label: 'Standard'}
]

export const statusOptions = [
  {value: 'created', label: 'Created'},
  {value: 'inactive', label: 'Inactive'}
]

export const accountPageTabs = [
  { index: 0, key: 'overview' },
  { index: 1, key: 'acc_debug'},
  { index: 2, key: 'acc_usage'},
  { index: 3, key: 'acc_deactivate' },
  { index: 4, key: 'users' },
  { index: 5, key: 'amz_accounts' },
]

export const organizationTabs = [
  { index: 0, key: 'overview' },
  { index: 1, key: 'org_accounts' },
  { index: 2, key: 'org_users' },
]

export const DT_FORMAT = 'YYYYMMDD'

export const REPORT_OPTIONS = [
  {'record_type':"keywords", 'campaign_type':'sb', 'segment':"query", "creative_type": "all"},
  {'record_type':"keywords", 'campaign_type':'sb', "creative_type": "all"},
  {'record_type':"targets", 'campaign_type':'sb', "creative_type": "all"},
  {'record_type':"campaigns", 'campaign_type':'sp', 'segment': 'placement'},
  {'record_type': "campaigns", 'campaign_type': 'sp'},
  {'record_type':"campaigns", 'campaign_type':'sb', 'segment': 'placement', 'creative_type':"all"},
  {'record_type':"campaigns", 'campaign_type':'sb', 'creative_type':"all"},
  {'record_type':"keywords", 'campaign_type':'sp'},
  {'record_type':"targets", 'campaign_type':'sp'},
  {'record_type':"keywords", 'campaign_type':'sp', 'segment':"query"},
  {'record_type':"targets", 'campaign_type':'sp', 'segment':"query"},
  {'record_type':"productAds", 'campaign_type':'sp'},
  {'record_type':"asins_targets", 'campaign_type':'sp'},
  {'record_type':"asins_keywords", 'campaign_type':'sp'},
  {'record_type':"campaigns", 'campaign_type':"sd", 'tactic':"T00020"},
  {'record_type':"campaigns", 'campaign_type':"sd", 'tactic':"T00030"},
  {'record_type':"productAds", 'campaign_type':"sd", 'tactic':"T00020"},
  {'record_type':"productAds", 'campaign_type':"sd", 'tactic':"T00030"},
  {'record_type':"targets", 'campaign_type':"sd", 'tactic':"T00020"},
  {'record_type':"targets", 'campaign_type':"sd", 'tactic':"T00030"},
  {'record_type': "asins", 'campaign_type': "sd", 'tactic': "T00020" },
  {'record_type': "asins", 'campaign_type': "sd", 'tactic': "T00030" }
]

export const SNAPSHOT_OPTIONS = [
  { 'record_type': 'campaigns', 'campaign_type': 'sp' },
  { 'record_type': 'campaigns', 'campaign_type': 'sb' },
  { 'record_type': 'campaigns', 'campaign_type': 'sd' },
  { 'record_type': 'adGroups', 'campaign_type': 'sp' },
  { 'record_type': 'adGroups', 'campaign_type': 'sb' },
  { 'record_type': 'adGroups', 'campaign_type': 'sd' },
  { 'record_type': 'keywords', 'campaign_type': 'sp' },
  { 'record_type': 'keywords', 'campaign_type': 'sb' },
  { 'record_type': 'negativeKeywords', 'campaign_type': 'sp' },
  { 'record_type': 'campaignNegativeKeywords', 'campaign_type': 'sp' },
  { 'record_type': 'campaignNegativeTargets', 'campaign_type': 'sp' },
  { 'record_type': 'negativeKeywords', 'campaign_type': 'sb' },
  { 'record_type': 'targets', 'campaign_type': 'sp' },
  { 'record_type': 'targets', 'campaign_type': 'sb' },
  { 'record_type': 'targets', 'campaign_type': 'sd' },
  { 'record_type': 'negativeTargets', 'campaign_type': 'sp' },
  { 'record_type': 'negativeTargets', 'campaign_type': 'sb' },
  { 'record_type': 'negativeTargets', 'campaign_type': 'sd' },
  { 'record_type': 'productAds', 'campaign_type': 'sp' },
  { 'record_type': 'productAds', 'campaign_type': 'sd' }
]
