import { Box, Grid, useColorModeValue, GridItem, ButtonSpinner, Input, Button, Text } from "@chakra-ui/react";
import DropdownButton from "components/buttons/dropdown_button";
import ConfigureCustomReport from "features/configure_custom_report";
import moment from "moment";
import { useState, useMemo } from "react";
import JSONPretty from "react-json-pretty";
import { AccountsService } from "services/accounts_service";
import { ProductDetailsService } from "services/product_details_service";
import { RecommendationsService } from "services/recommendations_service";
import { catchAsync } from 'util';
import SPAccounts from "./sp_accounts";
import MarketingStream from "./marketing_stream";
import BasicModal from 'components/modals/basic';
import CloneRuleset from "./clone_ruleset_account";
import ChangeOrg from "./change_org";
import Loader from 'components/loader';
import ScheduleImpactAnalysis from "./impact_analysis";

export default function AccountOverview({ account, accountId, forceRefresh=()=>{} }) {
    const [showActivityLogs, setShowActivityLogs] = useState(false);
    const [showCloneRulesetModal, setShowCloneRulesetModal] = useState(false);
    const [showOrgChangeModal, setShowOrgChangeModal] = useState(false);
    const [showImpactAnalysisModal, setShowImpactAnalysisModal] = useState(false);
    const [showCustomReport, setShowCustomReport] = useState(account.report_config);
    const [loading, setLoading] = useState(false)
    const boxBg = useColorModeValue("white", "blackAlpha.300");
    const [isEditing, setIsEditing] = useState(false);
    const [val, setValue] = useState(account.billed_adspend_percentage || 100);

    const handleScheduleRecommendations = catchAsync(async (entity) => {
        setLoading(true)
        const recsService = new RecommendationsService();
        await recsService.scheduleRecommendations(accountId, entity);
        setLoading(false)
    }, 'Recommendations scheduled successfully', 'Failed to schedule recommendations');

    const handleDeleteProductLabels = catchAsync(async () => {
        setLoading(true)
        const accountsService = new AccountsService();
        await accountsService.configureAccount({ account_id: accountId, flag: 'delete_product_labels' });
        setLoading(false)
    }, 'Product Labels deleted successfully', 'Failed to delete labels');

    const handleDeleteCampaignLabels = catchAsync(async () => {
        setLoading(true)
        const accountsService = new AccountsService();
        await accountsService.configureAccount({ account_id: accountId, flag: 'delete_campaign_labels' });
        setLoading(false)
    }, 'Campaign Labels deleted successfully', 'Failed to delete labels');

    const handleScheduleProductDetails = catchAsync(async () => {
        setLoading(true)
        const productDetailsService = new ProductDetailsService();
        await productDetailsService.scheduleProductDetails(accountId);
        setLoading(false)
    }, 'Product details scheduled successfully', 'Failed to schedule product details');

    const handleMarketingStream = catchAsync(async (enable) => {
        setLoading(true)
        const accountsService = new AccountsService();
        await accountsService.updateMarketingStream(accountId, enable)
        setLoading(false)
    }, 'Marketing Stream updated for the Acccount', 'Failed to update marketing stream');

    const refreshMarketingStream = catchAsync(async () => {
        setLoading(true)
        const accountsService = new AccountsService();
        await accountsService.refreshMarketingStream(accountId)
        setLoading(false)
        window.location.reload(false);
    }, 'Marketing Stream refreshed for the Acccount', 'Failed to refresh marketing stream');

    const handleActivateAccount = catchAsync(async () => {
        setLoading(true)
        const accountsService = new AccountsService();
        await accountsService.configureAccount({ account_id: accountId, flag: 'activate_account' })
        account.active = true
        account.fetch_data = true
        setLoading(false)
    }, 'Account has been activated', 'Error occurred while activating account');
    const updateAdSpendPercent = catchAsync(async (perc) => {
        setLoading(true)
        const accountsService = new AccountsService();
        await accountsService.updateAdSpendPercentage(accountId, perc)
        setLoading(false)
    }, 'AdSpend Percentage updated for the Acccount', 'Failed to update Adspend Percentage');

    const actions = useMemo(() => {
        const baseActions = [
            { label: 'Schedule Recommendations', onClick: () => handleScheduleRecommendations('rec') },
            { label: 'Schedule Audit', onClick: () => handleScheduleRecommendations('audit') },
            { label: 'Schedule Product Details', onClick: handleScheduleProductDetails },
            { label: 'Delete Product Labels', onClick: handleDeleteProductLabels, confirmable: true },
            { label: 'Delete Campaign Labels', onClick: handleDeleteCampaignLabels, confirmable: true },
            {label: 'Enable Marketing Stream', onClick: () => handleMarketingStream(true) },
            {label: 'Disable Marketing Stream', onClick: () => handleMarketingStream(false) },
            {label: 'Clone Ruleset Into Account', onClick: () => setShowCloneRulesetModal(true)},
            {label: 'Change Organisation', onClick: () => setShowOrgChangeModal(true)},
            {label: 'Refresh Marketing Stream Status', onClick: () => refreshMarketingStream()},
            {label: 'Schedule Impact Analysis', onClick: () => setShowImpactAnalysisModal(true)}
        ]

        if (!account.active || !account.fetch_data) {
            baseActions.push({ label: 'Activate Account', onClick: () => handleActivateAccount() });
        }

        return baseActions;
    }, [account.active, account.fetch_data]);

    const toggleEdit = () => {
        if (isEditing) {
          updateAdSpendPercent(val)
        }
        setIsEditing(!isEditing);
    };

    return <Box>
        <DropdownButton label="Actions" actions={actions} />
        {loading && <Loader />}
        <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Name:
                </GridItem>
                <GridItem colStart={2}>
                    {account.account_name}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Organization:
                </GridItem>
                <GridItem colStart={2}>
                    {account.orgs.map(org => org.name).join(', ')}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Account Id:
                </GridItem>
                <GridItem colStart={2}>
                    {account._id}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Last Modified:
                </GridItem>
                <GridItem colStart={2}>
                    {account.last_updated ? moment(account.last_updated).format("DD-MM-yyyy hh:mm A") : '--'}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Created:
                </GridItem>
                <GridItem colStart={2}>
                    {account.created ? moment(account.created).format("DD-MM-yyyy hh:mm A") : '--'}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Profile Account Name:
                </GridItem>
                <GridItem colStart={2}>
                    {account.profile_account_name}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Profile Account Type:
                </GridItem>
                <GridItem colStart={2}>
                    {account.profile_account_type}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Profile Account ID:
                </GridItem>
                <GridItem colStart={2}>
                    {account.profile_account_id}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Profile Marketplace String ID:
                </GridItem>
                <GridItem colStart={2}>
                    {account.profile_marketplace_string_id}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Amazon Login Email
                </GridItem>
                <GridItem colStart={2}>
                    {account.user_details?.email || '--'}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Adbrew User
                </GridItem>
                <GridItem colStart={2}>
                    {account.user?.email || '--'}
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(5, 1fr)' gap={2} mt={4} >
                <GridItem>
                    Ad Spend (%):
                </GridItem>
                <GridItem colStart={2}> 
                    {isEditing ? (<Input value={val} onChange={(e) => setValue(e.target.value)} fontWeight="bold"/>) : (<Text>{val}</Text>)} 
                </GridItem>
                <GridItem colStart={3}> 
                    <Button onClick={() => toggleEdit()}> {isEditing ? 'Save' : 'Edit'} </Button> 
                </GridItem>
            </Grid>
        <BasicModal size="xs" title="Clone Ruleset Into Account" isOpen={showCloneRulesetModal} onClose={() => setShowCloneRulesetModal(false)}>
            <CloneRuleset accountId={accountId} onComplete={() => setShowCloneRulesetModal(false)} />
        </BasicModal>
        <BasicModal size="md" title="Change Organisation" isOpen={showOrgChangeModal} onClose={() => setShowOrgChangeModal(false)}>
            <ChangeOrg accountId={accountId} onComplete={() => setShowOrgChangeModal(false)} />
        </BasicModal>
        <BasicModal size="md" title="Schedule Impact Analysis" isOpen={showImpactAnalysisModal} onClose={() => setShowImpactAnalysisModal(false)}>
            <ScheduleImpactAnalysis accountId={accountId} onComplete={() => setShowImpactAnalysisModal(false)} />
        </BasicModal>
        </Box>
        <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
            <div>Configure custom report:</div>
            {!showCustomReport && <span>Custom reports not configured. <span onClick={() => setShowCustomReport(true)} style={{ color: 'blue', cursor: 'pointer' }}>(Configure now)</span></span>}
            {showCustomReport && <ConfigureCustomReport accountId={account._id} reportConfig={account.report_config ?? []} />}
        </Box>
        <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
            <div>Activity Logs: <a style={{ color: 'blue', cursor: 'pointer', marginLeft: '20px' }} onClick={() => setShowActivityLogs(!showActivityLogs)}>{showActivityLogs ? 'Hide' : 'Show'}</a></div>
            {showActivityLogs && <JSONPretty data={account.activity_logs} />}
        </Box>
        <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
            <div>SP Accounts</div>
            <SPAccounts accounts={account.sp_accounts} accountId={account._id} forceRefresh={forceRefresh} />
        </Box>
        <Box bg={boxBg} marginTop={2} borderWidth='1px' borderRadius={10} padding={5} >
            <div>Marketing Stream</div>
            <MarketingStream data={account.marketing_stream_subscriptions} />
        </Box>
    </Box>
}